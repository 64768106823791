<template>
  <div class="card card-custom gutter-b" v-if="details && details.domain">
    <div class="card-header border-0">
      <h3 class="card-title d-flex domain-details-title">
        <span class="card-label font-weight-bolder text-dark">
          {{ details.domain }}
          <span
            class="svg-icon svg-icon-md svg-icon-danger"
            data-toggle="tooltip"
            v-b-tooltip.hover.top="'Has wrong selector'"
            v-if="details.has_wrong_selector"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
              <title>Stockholm-icons / General / Sad</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g
                id="Stockholm-icons-/-General-/-Sad"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                <rect
                  id="Combined-Shape"
                  fill="#000000"
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                ></rect>
                <path
                  d="M6.16794971,14.5547002 C5.86159725,14.0951715 5.98577112,13.4743022 6.4452998,13.1679497 C6.90482849,12.8615972 7.52569784,
                  12.9857711 7.83205029,13.4452998 C8.9890854,15.1808525 10.3543313,16 12,16 C13.6456687,
                  16 15.0109146,15.1808525 16.1679497,13.4452998 C16.4743022,12.9857711 17.0951715,12.8615972 17.5547002,
                  13.1679497 C18.0142289,13.4743022 18.1384028,14.0951715 17.8320503,14.5547002 C16.3224187,16.8191475 14.3543313,
                  18 12,18 C9.64566871,18 7.67758127,16.8191475 6.16794971,14.5547002 Z"
                  id="Path-56"
                  fill="#000000"
                  transform="translate(12.000000, 15.499947) scale(1, -1) translate(-12.000000, -15.499947) "
                ></path>
              </g>
            </svg>
          </span>
        </span>
        <div v-if="details.settings && details.settings.tags">
          <span
            v-for="tag in details.settings.tags"
            :key="`${tag}`"
            class="label label-lg label-light-primary label-inline font-weight-bold mr-1"
            >{{ tag }}</span
          >
        </div>
      </h3>
      <button
        class="btn btn-icon btn-light btn-sm my-auto"
        @click="show = true"
        v-b-tooltip.hover.left="'Settings'"
      >
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g fill="none" fill-rule="evenodd">
              <path d="M0 0h24v24H0z" />
              <path
                d="M7 3h10a4 4 0 1 1 0 8H7a4 4 0 1 1 0-8Zm0 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                fill="#6993FF"
              />
              <path
                d="M7 13h10a4 4 0 1 1 0 8H7a4 4 0 1 1 0-8Zm10 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                fill="#6993FF"
                opacity=".3"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>
    <div class="card-body pt-3">
      <div>
        <div
          style="display: flex;
    justify-content: flex-end;
}"
        >
          <DatePicker
            :clearable="false"
            style="width: 250px"
            :getPeriod="(data) => getPeriod(data)"
            :default_range="{
              date_from: firstDateOfMonth,
              date_to: new Date(),
            }"
          />
        </div>

        <LoaderCustom v-if="loading_stats" />

        <chart-line
          v-if="!loading_stats && dailyData.datasets[0].data.length"
          :height="300"
          :chartdata="dailyData"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
          }"
        >
        </chart-line>
        <div
          v-else-if="!loading_stats"
          class="card-label font-weight-bolder text-dark text-center mb-3"
        >
          No data
        </div>
      </div>
      <div class="domains-stats-wrp">
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">
            Total visits
          </div>
          <div class="font-size-h4 font-weight-bolder">
            {{ details.total_visits }}
          </div>
        </div>
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">IP</div>
          <div class="font-size-h4 font-weight-bolder">
            {{ details.first_ip }}
          </div>
        </div>
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">
            Country code
          </div>
          <div class="font-size-h4 font-weight-bolder">
            {{ details.country_code }}
          </div>
        </div>
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">Type</div>
          <div class="font-size-h4 font-weight-bolder">
            {{ details.domain_type === "l" ? "Lander" : "Offer" }}
          </div>
        </div>
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">Created at</div>
          <div class="font-size-h4 font-weight-bolder">
            {{ renderDate(details.created_at) }}
          </div>
        </div>
        <div class="domains-stats-wrp__item">
          <div class="font-size-sm text-muted font-weight-bold">
            Last visit at
          </div>
          <div class="font-size-h4 font-weight-bolder">
            {{ renderDate(details.last_visit_at) }}
          </div>
        </div>
      </div>
    </div>

    <domain-settings
      :show="show"
      :onHide="onHide"
      :onSubmit="onSubmit"
      :isLoading="false"
      :url="details.domain"
      :defaultSettings="details.settings ? details.settings : {}"
    />
  </div>
</template>

<script>
import DatePicker from "../../../../view/content/DatePicker.vue";
import LoaderCustom from "../../../../view/content/LoaderCustom.vue";

import { mapActions, mapState } from "vuex";
import moment from "moment";
import * as TYPES from "@/core/services/store/modules/domains/types";
import {
  renderDate,
  DATE_FORMAT,
  requestStatus,
  buildFilterString,
} from "@/core/services/services";

import ChartLine from "@/view/content/ChartLine";
import DomainSettings from "@/view/pages/Domains/components/DomainSettings";

export default {
  name: "DomainDetails",
  components: { ChartLine, DomainSettings, DatePicker, LoaderCustom },
  data: () => ({
    dateFilter: {
      date_from: "",
      date_to: "",
    },
    firstDateOfMonth: null,
    loading_stats: false,
    show: false,
    dailyData: {
      labels: [],
      datasets: [
        {
          label: "Visits",
          backgroundColor: "rgba(113, 106, 202, 0.5)",
          data: [],
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      details: (state) => state.domains.details,
      stats: (state) => state.domains.stats,
      status: (state) => state.domains.status,
    }),
    renderDate: () => renderDate,
  },
  methods: {
    ...mapActions({
      loadDataAction: `domains/${TYPES.GET_DOMAIN}`,
      loadStatsAction: `domains/${TYPES.GET_DOMAIN_STATS}`,
      onEditDomain: `domains/${TYPES.EDIT_DOMAIN}`,
    }),

    onGetStats() {
      this.loadStatsAction(
        `${buildFilterString({ ...this.dateFilter })}&ordering=-date&domain=${
          this.$route.params.id
        }`
      );
    },

    getPeriod(data) {
      this.dateFilter = data;
      this.onGetStats();
    },

    onHide: function () {
      this.show = false;
      // this.editDomain = null;
      this.loadDataAction(this.$route.params.id);
    },

    onSubmit: function (data) {
      this.show = false;
      let params = {
        settings: data.settings,
        has_wrong_selector: false,
      };
      this.onEditDomain({
        id: this.details.id,
        data: params,
      });
      // this.loadDataAction(this.$route.params.id);
      // this.editDomain = null;
    },

    transform: function (svg) {
      let title = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "title"
      );
      title.textContent = "";

      const oldTitle = svg.getElementsByTagName("title")[0];
      if (oldTitle) {
        svg.replaceChild(title, oldTitle);
      }
      return svg;
    },
  },
  watch: {
    stats(newValue) {
      this.dailyData.labels = [];
      this.dailyData.datasets[0].data = [];
      [...newValue].reverse().forEach((item) => {
        this.dailyData.labels.push(moment(item.date).format(DATE_FORMAT));
        this.dailyData.datasets[0].data.push(item.total_visits);
      });
    },
    status(newValue) {
      if (newValue.change_field === "load_stats") {
        this.loading_stats = newValue.load_stats === requestStatus.loading;
      }
    },
  },
  created: function () {
    this.firstDateOfMonth = new Date(
      new Date().setDate(new Date().getDate() - 30)
    );

    this.loadDataAction(this.$route.params.id);
  },
};
</script>

<style scoped lang="scss">
.domains-stats-wrp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
}

@media (max-width: 991px) {
  .domains-stats-wrp {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .domain-details-title {
    flex-direction: column;
    .card-label {
      width: 100%;
      margin: 0 0 4px 0 !important;
    }
  }
}
</style>
